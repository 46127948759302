import Polyglot from 'node-polyglot';
import { setStorageKey, StorageKeys } from './storage';
import {
  GroupDocSharedAttributes,
  GroupDocSharedCalculatedAttributes,
  GroupDocumentWithSubscription,
  GroupSharedDataDoc,
  LocaleString,
  TranslateKeys,
  TranslationObject,
} from '@og-shared/types';
import { exhaustiveCheck } from '@og-shared/utils';

const polyglot = new Polyglot();

export const locales: LocaleString[] = [
  'en',
  // 'de',
  'es',
  // 'fr',
  // 'it',
  // 'pt',
  // 'ru',
  // 'tr',
  // 'hi',
  // 'ja',
  // 'zh',
  // 'ko'
];

function getLanguageTranslations() {
  const languages: TranslationObject = {
    en: 'English',
    es: 'Español',
  };
  return languages;
}

async function getAppTranslations(locale: LocaleString) {
  const validLocale = locale;
  switch (validLocale) {
    case 'en': {
      const translationFile = await import('./locales/en');
      return { validLocale, translations: translationFile.default };
    }
    case 'es': {
      const translationFile = await import('./locales/es');
      return { validLocale, translations: translationFile.default };
    }
    default:
      exhaustiveCheck(validLocale);
  }
  const translationFile = await import('./locales/en');
  return {
    validLocale: 'en' as LocaleString,
    translations: translationFile.default,
  };
}

export async function initPolyglot(locale: LocaleString) {
  const { translations, validLocale } = await getAppTranslations(locale);

  await setStorageKey(StorageKeys.og_locale, validLocale);

  polyglot.extend(getLanguageTranslations());
  polyglot.extend(translations);
}

export async function extendTranslations(translations: any) {
  if (!translations) return;
  polyglot.extend(translations);
}

export const translate = (key: TranslateKeys | LocaleString, params?: any) => {
  return polyglot.t(key, params) as string;
};

export function translatePlaceholder(s: string) {
  // @todo translate
  console.warn(`translate placeholder: ${s}`);
  return s;
}

export function getEmptyTranslationObject() {
  const translations: TranslationObject = {
    en: '',
    es: '',
  };
  return translations;
}

export function getTranslationFromObject(
  translationObject: TranslationObject,
  key: LocaleString
) {
  if (!translationObject) return undefined;
  if (translationObject[key]) {
    return translationObject[key];
  }
  return translationObject.en;
}

export function getStoreName(store: GroupDocumentWithSubscription['paid_via']) {
  switch (store) {
    case 'ios': {
      return 'App Store';
    }
    case 'android': {
      return 'Play Store';
    }
    case 'partner': {
      return translate('PAID_VIA_PARTNER');
    }
    case 'stripe': {
      return 'Stripe;';
    }
    case null: {
      return translate('OG_UNKNOWN');
    }
    default: {
      exhaustiveCheck(store);
      return '';
    }
  }
}

export function translateGroupSharedDataDocKey(key: keyof GroupSharedDataDoc) {
  const groupAttributesLabelMap: Record<
    keyof GroupDocSharedAttributes,
    string
  > = {
    account_count: 'Accounts',
    analyze_spending: 'Analyze Spending Status',
    asa_item_count: 'Asa Connections',
    auto_fill: 'Auto Funding',
    budget_count: 'Categories ',
    created_by_uid: 'created_by_uid',
    expires: 'Expires',
    goal: 'Goal',
    invited_by_uid: 'Invited By UID',
    name: 'Name',
    needs_review_amount: 'Needs Review Amount',
    needs_review_count: 'Needs Review Count',
    og_utm_campaign: 'UTM Campaign',
    og_utm_content: 'UTM Content',
    og_utm_medium: 'UTM Medium',
    og_utm_source: 'UTM Source',
    onboarding: 'Onboarding',
    paid_via: 'Paid Via',
    partner_id: 'Partner ID',
    plaid_item_count: 'Plaid Item Count',
    status: 'status',
    total_available: 'Total Available',
    total_balanced: 'Total Balanced',
    total_in_budgets: 'Total in Budgets',
    uncategorized_transactions_amount: 'Uncategorized Amount',
    uncategorized_transactions_count: 'Uncategorized Count',
    user_count: 'Users',
  };
  const groupCalculatedAttributesLabelMap: Record<
    keyof GroupDocSharedCalculatedAttributes,
    string
  > = {
    accounts: translate('ACCOUNT_S', 2),
    budgets: translate('BUDGET_S', 2),
    cash_flow_ninety_days: '90 Day Cash Flow',
    cash_flow_ninety_end: '90 Day Cash Flow End Date',
    cash_flow_ninety_start: '90 Day Cash Flow Start Date',
    cash_flow_seven_days: '7 Day Cash Flow',
    cash_flow_seven_end: '7 Day Cash Flow End Date',
    cash_flow_seven_start: '7 Day Cash Flow start Date',
    cash_flow_thirty_days: '30 Day Cash Flow',
    cash_flow_thirty_end: '30 Day Cash Flow End Date',
    cash_flow_thirty_start: '30 Day Cash Flow Start Date',
    planned_monthly_expenses: 'Planned Expenses',
    planned_monthly_income: 'Planned Income',
    total_assets: 'Assets Total',
    total_brokerage: 'Brokerage Total',
    total_credit: 'Credit Total',
    total_depository: 'Depository Total',
    total_investment: 'Investments Total',
    total_liabilities: 'Liabilities Total',
    total_loan: 'Loans Total',
    total_net_worth: 'Net Worth',
    total_other: 'Other Total',
    total_property: 'Property Total',
  };
  const groupSharedDataLabelMap: Record<keyof GroupSharedDataDoc, string> = {
    ...groupAttributesLabelMap,
    ...groupCalculatedAttributesLabelMap,
    group_id: 'Group ID',
    last_activity: 'Last Activity',
    last_updated: 'Last Updated',
  };
  return groupSharedDataLabelMap[key];
}
